var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _c("section", { staticClass: "banner" }, [
      _c("img", { attrs: { src: require("./img/banner.png") } }),
      _vm._v(" "),
      _c("div", { staticClass: "store-link" }, [
        _c(
          "div",
          {
            staticClass: "image",
            on: {
              click: function ($event) {
                return _vm.downloadIOS()
              },
            },
          },
          [_c("img", { attrs: { src: require("./img/app-store.png") } })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "image",
            on: {
              click: function ($event) {
                return _vm.downloadAndroid()
              },
            },
          },
          [_c("img", { attrs: { src: require("./img/google-play.png") } })]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "banner-mobile" }, [
      _c("img", { attrs: { src: require("./img/banner-mobile.png") } }),
      _vm._v(" "),
      _c("div", { staticClass: "store-link" }, [
        _c(
          "div",
          {
            staticClass: "image",
            on: {
              click: function ($event) {
                return _vm.downloadIOS()
              },
            },
          },
          [_c("img", { attrs: { src: require("./img/app-store.png") } })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "image",
            on: {
              click: function ($event) {
                return _vm.downloadAndroid()
              },
            },
          },
          [_c("img", { attrs: { src: require("./img/google-play.png") } })]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _vm._m(5),
    _vm._v(" "),
    _vm._m(6),
    _vm._v(" "),
    _vm._m(7),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container function" }, [
      _c("h4", [_vm._v("SIAPA KAMI")]),
      _vm._v(" "),
      _c("img", { attrs: { src: require("./img/function-schema.png") } }),
      _vm._v(" "),
      _c("div", { staticClass: "instruction" }, [
        _c("p", { staticClass: "lender" }, [
          _vm._v(
            "\n                Easycash adalah sebuah produk atau platform yang dikembangkan dan dikelola oleh PT Indonesia Fintopia Technology (Fintopia).\n                Kami adalah Penyelenggara Layanan pribadi dengan pribadi lending yang telah terdaftar dan diawasi oleh Otoritas Jasa\n                Keuangan (OJK) dengan nomor registrasi S-590/NB.213/2018. Tujuan dari Fintopia adalah mempromosikan inklusi\n                keuangan ke seluruh dunia dengan menggunakan teknologi. Kami membangun sebuah pasar online untuk menghubungkan\n                Pemberi Pinjaman dan Penerima Pinjaman. Bagi Pemberi Pinjaman, kami menggunakan sistem self-learning credit\n                untuk menemukan pinjaman yang sesuai dengan mereka dalam hitungan menit. Ini berarti\n                Penerima Pinjaman dapat menerima pinjaman mereka dengan sangat cepat setelah mereka mengajukan pinjaman dan\n                memenuhi kebutuhan mendesak harian mereka.\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "borrower" }, [
          _c("p", [
            _vm._v(
              "\n                    Ide dan teknologi Fintopia berasal dari co-founder kami yang sebelumnya bekerja untuk Facebook, Google, dan Microsoft di\n                    Silicon Valley. Dimulai dari China pada tahun 2015, kami telah memperluas bisnis kami ke Jepang, Thailand,\n                    Brazil dan Indonesia. Kami telah melayani 17,3 juta nasabah dan telah memfasilitasi pinjaman senilai\n                    Rp. 8,2 triliun rupiah di seluruh dunia.\n                "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "margin-top": "20px" } }, [
            _vm._v(
              "\n                    Kami mulai beroperasi sejak bulan Agustus 2017 di Indonesia dan memiliki tim bertalenta yang sebelumnya bekerja di Bank\n                    Mandiri, BCA, dan insitusi keuangan konvensional lainnya. Sampai dengan saat ini, kami telah menyalurkan\n                    lebih dari 115 miliar Rupiah kepada lebih dari 1 juta nasabah. Dan tercatat 0% kerugian bagi para investor kami.\n                "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container advance" }, [
      _c("h4", [_vm._v("FASILITAS UNTUK PEMBERI PINJAMAN")]),
      _vm._v(" "),
      _c("div", { staticClass: "advance-item" }, [
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/advance-income.png") } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "description" }, [
          _c("h5", [
            _vm._v("Tingkat return tinggi dan dapat diambil kapan saja!"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Dapatkan return tahunan yang luar biasa sebesar 20% dan bunga akan dihitung secara harian. Sebagai contoh, apabila Anda memberikan\n                    pinjaman sebesar 20.000.000 rupiah pada tanggal 17 Juni. Setiap harinya Anda akan mendapatkan bunga sebesar\n                    12.000 rupiah. Anda juga dapat mengambil uang Anda kapan dan dimana saja melalui aplikasi kami. Jika\n                    Anda ingin mengambil pada tanggal 28, Anda akan menerima bunga untuk 10 hari yaitu sekitar 120.000 rupiah.\n                "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "advance-item" }, [
        _c("div", { staticClass: "description" }, [
          _c("h5", [_vm._v("Catatan kerugian 0%!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Seluruh bisnis global kami tidak pernah membiarkan investor kami mengalami kerugian. Kami memiliki sistem kredit\n                    yang efektif untuk nasabah yang menjadi sasaran kami, yang dikembangkan berdasarkan pengetahuan keuangan\n                    tradisional dan teknologi AI yang dapat belajar secara mandiri dan membedakan setiap peminjam yang baik\n                    dan peminjam yang buruk. Di sisi lain, sistem kami akan secara otomatis membantu Anda untuk menginvestasi\n                    ke berbagai pinjaman dan hal ini akan membuat risiko menjadi tersebar.\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/advance-lose.png") } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "advance-item" }, [
        _c("div", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: require("./img/advance-depository.png") },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "description" }, [
          _c("h5", [
            _vm._v(
              "Escrow account, VA Bank Mandiri dan Alfamart membantu menjaga keamanan dana!"
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Kami memiliki kemitraan dengan Bank Mandiri dan BCA dalam escrow account. Dana yang diberikan oleh Pemberi Pinjaman akan\n                    langsung masuk ke rekening escrow account tersebut. Dana tersebut tidak dapat kami sentuh dan hanya dapat\n                    digunakan untuk penerima pinjaman kami. Lalu ketika penerima pinjaman melakukan pembayaran, dana tersebut\n                    akan langsung masuk ke escrow account melalui VA Mandiri atau Alfamart.\n                "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "advance-item" }, [
        _c("div", { staticClass: "description" }, [
          _c("h5", [
            _vm._v(
              "Tim teknologi berpengalaman membantu menjaga keamanan data dan pelayanan!"
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Tim teknologi inti kami berasal dari Facebook dan Microsoft. Platform pembayaran Facebook dibuat dan dikembangkan oleh mereka,\n                    yang telah menghasilkan milayar dolar Amerika Serikat setiap tahunnya. Mereka juga sangat berpengalaman\n                    dalam akurasi dan keamanan platform. Kami juga telah memiliki sistem monitor yang kami kembangkan sendiri\n                    serta pengaturan pemanggilan untuk menjaga sistem kami sestabil mungkin.\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/advance-team.png") } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "advance-item" }, [
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/advance-partner.png") } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "description" }, [
          _c("h5", [_vm._v("Tim bertalenta!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Tim kami terdiri dari bidang keuangan dan teknologi, keduanya bertalenta domestik dan internasional yang dapat membawa\n                    pengalaman luar negeri dan teknologi yang kemudian digabungkan dengan lingkungan Indonesia. Mereka berasal\n                    dari Bank Mandiri, BCA, HSBC, Astra Credit Companies, Facebook, Google dan Microsoft.\n                "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "advance-item" }, [
        _c("div", { staticClass: "description" }, [
          _c("h5", [_vm._v("Kemitraan dengan lembaga terkenal!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Kami telah terdaftar dan diawasi oleh OJK dan kami tentu saja akan mengikuti seluruh peraturan dari OJK. Selain itu, kami\n                    memiliki mitra terkena di setiap area. Seperti kami memiliki kerja sama yang baik dengan bank-bank, seperti Bank Mandiri, BCA dan\n                    lainnya. Penasihat hukum kami adalah badan hukum dengan peringkat 3 teratas di Indonesia. Lebih lanjut pelayanan\n                    kami didukung oleh Biznet, Alfamart, dan lain-lain.\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/advance-ojk.png") } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container find-borrower" }, [
      _c("h4", [
        _vm._v(
          "BAGAIMANA KAMI MEMBANTU ANDA UNTUK MENEMUKAN PENERIMA PINJAMAN YANG TEPAT"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper" }, [
        _c("div", { staticClass: "left" }, [
          _c("h5", [_vm._v("Perkenalan tentang penerima pinjaman")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Penerima pinjaman yang menjadi sasaran kami adalah Generasi Muda Indonesia. Karena kurangnya riwayat kredit, mereka kesulitan\n                    untuk mendapatkan pinjaman dari institusi keuangan sehingga tujuan kami adalah untuk membantu mereka\n                    mendapatkan dana untuk kebutuhan mendesak atau harian mereka. Penerima pinjaman kami saat ini memiliki\n                    karakteristik; mulai dari kota besar seperti Jakarta, Surabaya, dan lainnya; berusia 23\n                    sampai 35 tahun; memiliki pekerjaan dan mendapatkan bayaran mingguan atau bulanan. Kami telah membantu\n                    lebih dari 500.000 peminjam untuk mendapatkan pinjaman. Ayo bergabung dengan kami untuk membantu mereka!\n                "
            ),
          ]),
          _vm._v(" "),
          _c("img", {
            attrs: { src: require("./img/find-borrower-grass.png") },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "person" }, [
          _c("img", {
            attrs: { src: require("./img/find-borrower-person.png") },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container find-borrower-mobile" }, [
      _c("h4", [
        _vm._v(
          "BAGAIMANA KAMI MEMBANTU ANDA UNTUK MENEMUKAN PENERIMA PINJAMAN YANG TEPAT?"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("h5", [_vm._v("Perkenalan tentang penerima pinjaman")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                Penerima pinjaman yang menjadi sasaran kami adalah Generasi Muda Indonesia. Karena kurangnya riwayat kredit, mereka kesulitan\n                untuk mendapatkan pinjaman dari institusi keuangan sehingga tujuan kami adalah untuk membantu mereka\n                mendapatkan dana untuk kebutuhan mendesak atau harian mereka. Penerima pinjaman kami saat ini memiliki\n                karakteristik; mulai dari berasal dari kota besar seperti Jakarta, Surabaya, dan lainnya; berusia 23\n                sampai 35 tahun; memiliki pekerjaan dan mendapatkan bayaran mingguan atau bulanan. Kami telah membantu\n                lebih dari 500.000 peminjam untuk mendapatkan pinjaman. Ayo bergabung dengan kami untuk membantu mereka!\n            "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container borrowers" }, [
      _c("h4", [_vm._v("TIPIKAL PEMINJAM")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "borrowers-item" }, [
          _c("div", { staticClass: "image" }, [
            _c("img", { attrs: { src: require("./img/borrowers-man.png") } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail" }, [
            _c("span", { staticClass: "age" }, [_vm._v("Fadhly, 19")]),
            _vm._v(" "),
            _c("span", { staticClass: "name" }, [
              _vm._v("Pekerjaan: Baru Lulus"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "description" }, [
              _vm._v(
                "\n                        Fadhly adalah seorang pria yang baru saja lulus kuliah dan mendapatkan pekerjaan di Jakarta. Akan tetapi dia tidak memiliki\n                        uang untuk menyewa rumah atau untuk menetap. Di sisi lain dia saat ini sangat butuh untuk mendapatkan\n                        uang ini. Jika dia mengajukan pinjaman melalui jalur lainnya, hal itu akan memakan waktu lebih\n                        dari 2 minggu. Sekarang kita dapat segera membantunya!\n                    "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "borrowers-item" }, [
          _c("div", { staticClass: "detail" }, [
            _c("span", { staticClass: "age" }, [_vm._v("Fitri,24")]),
            _vm._v(" "),
            _c("span", { staticClass: "name" }, [
              _vm._v("Pekerjaan: Berkerja"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "description" }, [
              _vm._v(
                "\n                        Fitri adalah seorang wanita yang telah bekerja pada sebuah perusahaan pabrik tekstil besar selama 3 tahun. Saat ini dia membutuhkan\n                        handphone agar dapat video call dengan orang tuanya. Akan tetapi dia belum memiliki cukup uang untuk\n                        membeli sebuah handphone baru. Di sisi lain, dia belum mempunyai kesempatan untuk meminjam uang dari\n                        bank. Namun kita dapat membantunya sekarang!\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "image" }, [
            _c("img", { attrs: { src: require("./img/borrowers-women.png") } }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container safe" }, [
      _c("h4", [
        _vm._v(
          "MENGAPA INVESTASI MELALUI EASYCASH PEMBERI PINJAMAN LEBIH AMAN"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "reason-item" }, [
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/safe-systerm.png") } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "detail" }, [
          _c("h5", [_vm._v("Sistem kredit self-evolving yang mutakhir")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Sistem kredit self-evolving machine learning menjalankan lebih dari 6.000 fitur dan lebih dari 10 jenis analisis scenario\n                    yang dapat merefleksikan keseluruhan kondisi kredit dari peminjam kami.\n                "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "reason-item" }, [
        _c("div", { staticClass: "detail" }, [
          _c("h5", [
            _vm._v(
              "Teknologi mutakhir untuk melakukan manajemen risiko ke tingkat yang lebih tinggi"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
            _vm._v(
              "\n                    Fintopia memiliki tim keuangan yang berpengalaman dan mereka membawa pengalaman dan keterampilan manajemen risiko kredit\n                    dari bank internasional dan Indonesia seperti HSBC, BCA, Mandiri, dll.\n                "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "margin-top": "0" } }, [
            _vm._v(
              "\n                    Selain itu, kami juga menggabungkan teknologi termutakhir untuk meningkatkan proses manajemen risiko kredit ini lebih baik\n                    dari pada yang telah berlaku di bank.\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/safe-technology.png") } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "reason-item" }, [
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/safe-borrowers.png") } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "detail" }, [
          _c("h5", [_vm._v("Investasi beragam pada peminjam yang berbeda")]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(
              "\n                    Suatu investasi beragam adalah suatu portofolio yang terdiri dari peminjam yang berbeda yang menghasilkan return tertinggi\n                    dengan risiko terendah. Sistem kami akan membantu Anda untuk investasi ke ribuan peminjam untuk menghindari\n                    tindakan curang dari salah satu di antaranya.\n                "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container guide" }, [
      _c("h4", [_vm._v("CARA INVESTASI")]),
      _vm._v(" "),
      _c("div", { staticClass: "image" }, [
        _c("img", { attrs: { src: require("./img/guide-steps-1.png") } }),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "1.Registrasi – masukkan nomor telepon, KTP dan nomor rekening."
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "image" }, [
        _c("img", { attrs: { src: require("./img/guide-steps-2.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "detail" }, [
        _c("p", [
          _vm._v(
            "\n                2.Pilih opsi APP yang sesuai\n            "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.Memeriksa pengembalian – Buka aplikasi dan periksa pengembaliannya"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "contact-info" }, [
        _c("p", [
          _vm._v("Layanan Pelanggan: "),
          _c("a", { attrs: { href: "tel:(021) 806 31332" } }, [
            _vm._v("(021) 806 31332"),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Alamat perusahaan: Menara Dea Tower 1, Lantai 15 Suite 1501\n               Jalan Mega Kuningan Barat No.1-2 Kav E4\n            "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Kuningan Timur, Setiabudi, 12950\n               Jakarta Selatan, Indonesia\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "notice" }, [
        _c("h4", [_vm._v("Perlindungan Nasabah dan Pemberitahuan")]),
        _vm._v(" "),
        _c("div", { staticClass: "divide" }, [
          _c("div", [
            _c("p", { staticStyle: { "margin-bottom": "0" } }, [
              _vm._v(
                "Peminjam diharapkan menghindari Rerisiko kredit atau gagal bayar yang timbul dari pinjaman easycash."
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticStyle: { margin: "0" } }, [
              _vm._v(
                "Yang dimana mengakibatkan penyelenggara akan menguhubungi kontak darurat yang dicantumkan melalui pengisian\n                        formulir pada pengajuan pinjaman di aplikasi Easycash."
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticStyle: { "margin-top": "0" } }, [
              _vm._v(
                "Dan juga peminjam dapat terlapor sebagai kredit macet pada SLIK ( Sistem Layanan Informasi Keuangan )\n                        yang dikelola oleh OJK , dimana akan merekam riwayat kesehatan finansial masyarakat Indonesia yang\n                        nantinya dapat mempengaruhi dari laporan kesehatan finansial peminjam."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "1. Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi (“Pinjaman Fintech”) merupakan bentuk persetujuan\n                        dan kesepakatan secara keperdataan antara Pemberi Pinjaman dan Penerima Pinjaman, sehingga segala\n                        bentuk risiko dan akibat hukum yang timbul karenanya sepenuhnya akan ditanggung oleh masing-masing\n                        pihak."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "2. Resiko Kredit atau Gagal Bayar yang timbul dari Pinjaman Fintech sepenuhnya menjadi tanggungjawab\n                        dari Pemberi Pinjaman. Tidak ada lembaga atau otoritas negara yang bertanggung jawab atas Risiko\n                        Kredit atau Gagal Bayar tersebut atau memberikan kompensasi atas kerugian atau konsekuensi lainnya\n                        yang timbul sehubungan dengan hal tersebut dalam bentuk apapun."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "3. Penyelenggaran dengan persetujuan dari setiap Pengguna (baik Pemberi Pinjaman maupun Penerima Pinjaman)\n                        mengakses, memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna (“Pemanfaatan\n                        Data) pada atau di dalam benda, perangkat elektronik (termasuk smartphone atau telepon seluler),\n                        perangkat keras (hardware) maupun lunak (software), dokumen elektronik, aplikasi atau sistem elektronik\n                        milik Pengguna atau yang dikuasai oleh Pengguna, dengan memberitahukan tujuan, Batasan, dan mekanisme\n                        Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum memperoleh persetujuan yang dimaksud."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "4. Pemberi Pinjaman yang belum memiliki pengetahuan dan pengalaman dalam hal Pinjam Meminjam Berbasis\n                        Teknologi Informasi atau Pinjaman Fintech, disarankan untuk tidak menggunakan layanan Pinjaman Fintech\n                        ini."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("p", [
              _vm._v(
                "5. Penerima Pinjaman wajib untuk mempertimbangkan tingkat suku bunga pinjaman serta biaya-biaya lainnya\n                        sesuai dengan kemampuannya dalam membayar pinjaman sebelum menggunakan fasilitas Pinjaman Fintech."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "6. Setiap kecurangan yang timbul pada Pinjaman Fintech akan tercatat secara digital di dunia maya dan\n                        dapat diketahui masyarakat luas melalui media sosial serta dapat menjadi alat bukti hukum yang sah\n                        menurut peraturan perundang-undangan yang berlaku tentang informasi dan transaksi elektronik dalam\n                        proses penyelesaian sengketa dan penegakan hukum."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "7. Masyarakat Pengguna wajib membaca dan memahami informasi ini sebelum mengambil keputusan untuk menjadi\n                        Pemberi Pinjaman ataupun menjadi Penerima Pinjaman. Keputusan Pengguna untuk menggunakan fasilitas\n                        Pinjaman Fintech ini membuktikan bahwa Pengguna telah memahami Informasi ini."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "8. Pemerintah, yaitu dalam hal ini Otoritas Jasa Keuangan, tidak bertanggung jawab atas setiap pelanggaran\n                        atau ketidakpatuhan oleh Pengguna, baik Pemberi Pinjaman maupun Penerima Pinjaman (baik karena kesengajaan\n                        atau kelalaian Pengguna) terhadap ketentuan peraturan perundang-undangan maupun kesepakatan atau\n                        perikatan antara Penyelenggara dengan Pemberi Pinjaman dan/atau Penerima Pinjaman."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "9. Setiap transaksi dan kegiatan pinjam meminjam atau pelaksanaan kesepakatan mengenai pinjam meminjam\n                        antara atau yang melibatkan Penyelenggara, Pemberi Pinjaman dan/atau Penerima Pinjaman wajib dilakukan\n                        melalui escrow account dan virtual account sebagaimana diwajibkan berdasarkan Peraturan Otoritas\n                        Jasa Keuangan Nomor 77/POJK.01/2016 tentang Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi.\n                        Pelanggaran atau ketidakpatuhan terhadap ketentuan tersebut merupakan bukti telah terjadinya pelanggaran\n                        hukum oleh Penyelenggara sehingga Penyelenggara wajib menanggung ganti rugi yang diderita oleh masing-masing\n                        Pengguna sebagi akibat langsung dari pelanggaran hukum tersebut di atas tanpa mengurangi hak Pengguna\n                        yang menderita kerugian tersebut menurut Kitab Undang-Undang Hukum Perdata."
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "final-footer" }, [
        _c("span", [_vm._v("©PT Indonesia Fintopia Technology")]),
        _vm._v(" "),
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("./img/app-icon.png") } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }