/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:23
 */

const ANDROID_LINK = 'https://download.geteasycash.asia/Easycash.apk';
const IOS_LINK = 'https://itunes.apple.com/id/app/id1435044790?l=zh&ls=1&mt=8';

export default {
    name: 'Index',

    methods: {
        downloadIOS() {
            window.open(IOS_LINK);
        },

        downloadAndroid() {
            window.open(ANDROID_LINK);
        }
    }
};
